var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column flex-root fill-height"
  }, [_c('div', [_c('v-app-bar', {
    staticClass: "d-flex align-center justify-center",
    attrs: {
      "absolute": "",
      "app": "",
      "color": "primary",
      "flat": "",
      "height": "80"
    }
  }, [_c('a', {
    staticClass: "text-center pa-5",
    attrs: {
      "href": "/"
    }
  }, [_c('img', {
    staticClass: "max-h-70px",
    attrs: {
      "src": "/media/logos/logo_light_155x30.png",
      "alt": "TestApp.io"
    }
  })])])], 1), _c('div', {
    staticClass: "transparent d-flex flex-column justify-center align-center px-10 pt-15 mt-10",
    staticStyle: {
      "min-height": "100vh"
    }
  }, [_c('img', {
    staticClass: "max-h-400px",
    attrs: {
      "src": "/media/error/empty_image.png",
      "alt": ""
    }
  }), _c('div', {
    staticClass: "pt-6 bold-text error-title max-w-700px"
  }, [_vm._v(" " + _vm._s(_vm.message || "Something went wrong, please try again") + " ")]), _c('div', [_c('v-btn', {
    staticClass: "main primary mt-6",
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          name: 'dashboard'
        });
      }
    }
  }, [_vm._v(" Go to home page ")])], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }