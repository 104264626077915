<!-- Error demo pages -->
<template>
  <!-- <transition name="fade-in-up">
    <router-view></router-view>
  </transition>-->
  <div class="d-flex flex-column flex-root fill-height">
    <div>
      <v-app-bar
        absolute
        app
        color="primary"
        flat
        class="d-flex align-center justify-center"
        height="80"
      >
        <a href="/" class="text-center pa-5">
          <img
            src="/media/logos/logo_light_155x30.png"
            class="max-h-70px"
            alt="TestApp.io"
          />
        </a>
      </v-app-bar>
    </div>
    <div
      class="transparent d-flex flex-column justify-center align-center px-10 pt-15 mt-10"
      style="min-height: 100vh"
    >
      <img src="/media/error/empty_image.png" class="max-h-400px" alt />
      <div class="pt-6 bold-text error-title max-w-700px">
        {{ message || "Something went wrong, please try again" }}
      </div>
      <div>
        <v-btn
          class="main primary mt-6"
          @click="$router.push({ name: 'dashboard' })"
        >
          Go to home page
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      message: "",
    };
  },
  created() {
    if (this.$route.params.message) {
      this.message = this.$route.params.message;
    }
  },
  methods: {
    goBack() {
      window.history.back();
    },
  },
};
</script>
